textarea {
    width: 100%;
    height: 150px;
    padding: 12px 20px;
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    resize: none;
}

@media only screen and (min-width:601px){
    .topMedium {
        display: flex;
        flex-direction:column-reverse;
    }
}

@media only screen and (max-width:601px){
    .topMedium {
        display: flex;
        flex-direction:column-reverse;
    }
}

@media only screen and (min-width:993px){
    .topMedium {
        display: flex;
        flex-direction:initial;
    }
}