.hf {
	text-decoration: none;
	color:white;
	background-color: #A52A2A;
	padding: 0px;
	margin:0;
	padding:10px 20px 0px 0px;
	font-family: Segoe;
	font-size: 25px;
}

@font-face {
	font-family: Segoe;
	src: local('Segoe Script'), url(../../Fonts/SegoeScript.ttf) format('ttf');
}

.w3-navbar {      
    background-color: white;      
}

.center {
	display: flex;
    justify-content: center; 
}

.menuButton {
	background-color: white;
    text-shadow: none;
}

.menuSmall {
	background-color: #A52A2A;
}

.w3-navbar li {
	color: #A52A2A; 
	font-size: 20px;
}

.large-navbarli {
	width: 250px;
	display: flex;
	justify-content: center;
}

.large-navbarli2 {
	width: 250px;
	display: flex;
	justify-content: flex-end;
}

.large-navbarli3 {
	width: 250px;
	display: flex;
	justify-content: flex-start;
}


.menuItem {
	margin: 5px 0px 5px 0px;
}

@media screen and (max-width:992px){.w3-topnav a{display:block}.w3-navbar li:not(.w3-opennav){float:none;width:100%!important}.w3-navbar li.w3-right{float:none!important}}	
@media screen and (max-width:992px){.w3-topnav .w3-dropdown-hover .w3-dropdown-content,.w3-navbar .w3-dropdown-click .w3-dropdown-content,.w3-navbar .w3-dropdown-hover .w3-dropdown-content{position:relative}}	
@media screen and (max-width:992px){.w3-topnav,.w3-navbar{text-align:center}}