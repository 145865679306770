.brown {
  color: #A52A2A;
}

.brownBackground {
	background-color: #A52A2A; 
}

.green {
	color: #004d00;
}

.price {	
	font-weight: bold;
	margin-top: 20px;
}

.grey {
	color:#4e4e4e;
}

.greyBackground {
	background-color: #D3D3D3;
}

.center {
	display: flex;
	justify-content: center;
}

.noSelect {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}