.cardmin {
	min-width: 300px;
}

.w3-white {
	color: green;
}

.title {	
	margin-bottom: 0px;
	margin-top: 0px;
}

.title:hover {
	text-shadow:1px 1px 0 #444;
}

.smallDescription {
	margin-top: 0px;
	margin-bottom: 0px;
}

.imgcard {
	width:100%;
}