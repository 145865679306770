.Demo__some-network__share-button {
    cursor: pointer;
}
  
.Demo__some-network__share-button:hover:not(:active) {
    opacity: 0.75;
}
.last-item {
    margin-left: auto;
    margin-right: auto;
}

.mySlides {display:none}

.filter-inactive {
  filter: brightness(0.4);
}

.filter-active {
  filter: brightness(1);
}