@media screen and (max-width:992px){
    .img {
        height: 350px;
    }
    .smallSize{
        font-size: 20px;
    }
    .margin{
        margin-top: 120px;
    }
}

@media screen and (max-width:600px){
    .img {
        height: 250px;
    }
    .margin{
        margin-top: 80px;
    }
    .smallSize{
        font-size: 15px;
    }
}

@media screen and (min-width:992px){
    .img {
        height: 450px;
    }
    .margin{
        margin-top: 150px;
    }
}

